
body {
    overscroll-behavior-y: contain;
}

#info_text {
    color:'#000';
    vertical-align: 'center';
    text-align: 'left';
    width: '94%';
    margin-bottom: 10;
    margin-top: 10;

    font-weight: 405;
    font-size: 15px;
}

.linkButton {
    font-size: 15px !important;
    font-weight: 450 !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}

.discount-label {
    padding:8px; 
    position:relative;
     float:left;
     margin:10px;
   width:"20%";
   -webkit-border-radius:0 4px 0 4px;
   -moz-border-radius:0 4px 0 4px;
   border-radius:0 4px 4px 0;
   
   }
   
   .discount-label:after { 
   
       right: 100%; 
       border: solid transparent; content: " "; 
       height: 0; 
       width: 0; 
       position: absolute;
       border-color: rgba(136, 183, 213, 0);
        
       border-width: 20px; 
       top: 50%; 
       margin-top: -20px;} 
   
       .discount-label:before {
     content: '';
     z-index: 2;
     position: absolute;
     top: 42%;
     right: 100%;
     width: 7px;
     height: 7px;
     opacity: .95;
     background: #ffffff;
     border-radius: 7px;
     -webkit-box-shadow: inset .5px 0 rgba(0, 0, 0, 0.6);
     box-shadow: inset .5px 0 rgba(0, 0, 0, 0.6);
   
   }
   
   
       .discount-label span {
         color:#ffffff;
     font-size:20px;
   text-align:center;
     font-family:"Raleway",Helvetica;
   
   }
   
   
   .red{ 
   background-color:#E80707; 
   }
   
   .red:after{ 
   border-right-color: #E80707;
     
   }
   
   
   .blue{ 
   background-color:#1883DB; 
   }
   .blue:after{ 
   border-right-color: #1883DB; 
   }
   
   .green{ 
   background-color:#16B516; 
   }
   
   .green:after{ 
   border-right-color: #16B516;
   }
   
   .yellow{ 
   background-color:#feaa00; 
   }
   
   .yellow:after{ 
   border-right-color: #feaa00;
   }
   
   .gray{ 
   background-color:#737373; 
   }
   
   .gray:after{ 
   border-right-color: #737373;
   }
   
   .black{ 
   background-color:#000; 
   
   }
   
   .black:after{ 
   border-right-color: #000;
   }