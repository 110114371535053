
#info_text {
    color:'#000';
    vertical-align: 'center';
    text-align: 'left';
    width: '94%';
    margin-bottom: 10;
    margin-top: 10;

    font-weight: 405;
    font-size: 15px;
}

body {
    overscroll-behavior-y: contain;
}
.listaProdutos {
    min-height: 100px;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.my-loading:empty {
    background-color: #ddd;
    animation: blinker 1.3s linear infinite;
    border-radius: 10px;
    opacity: 0.8;
}

.linkButton {
    font-size: 15px !important;
    font-weight: 450 !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}

@keyframes blinker {
    50% {
      opacity: 0;
    }
  }

.ReactModal__Body--open {
    overflow: hidden;
    position: fixed;
    width: 100%;
    height: 100%;
}

.awssld__bullets {
    bottom: 10px;
    z-index: 2;
}

.awssld__bullets button{
    width: 10px;
    height: 10px;
}

@media (max-width: 500px){
    .awssld__container {
        padding-bottom: var(--slider-height-percentage);
    }
}

.awssld {
    --control-bullet-active-color: #f0f;
    --control-bullet-color: #f0f;
    --organic-arrow-color: #f0f;
}


