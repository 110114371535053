.myLoadingGIF {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    display: block;
    opacity: 0.8;
    background-color: #fff;
    z-index: 9999;
    text-align: center;
}

button {
    font-size: 15px !important;
    font-weight: 450 !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}

.submit {
    height: 40px !important;
}

body {
    overscroll-behavior-y: contain !important;
    -webkit-overflow-scrolling: touch  !important;
}



